import {
  format,
  getTime,
  formatDistanceToNow,
  formatDistanceStrict,
  formatDistanceToNowStrict,
  addHours,
} from 'date-fns';

// ----------------------------------------------------------------------

export function fDate(date: Date, newFormat?: string) {
  const fm = newFormat || 'dd MMM yyyy';

  return date ? format(new Date(date), fm) : '';
}

export function fDateTime(date: Date, newFormat?: string) {
  const fm = newFormat || 'dd MMM yyyy p';

  return date ? format(new Date(date), fm) : '';
}

export function fTimestamp(date: Date) {
  return date ? getTime(new Date(date)) : '';
}

export function fToNow(date: Date) {
  return date
    ? formatDistanceToNow(new Date(date), {
        addSuffix: true,
      })
    : '';
}

export function formatRelativeDate(from: Date) {
  const currentDate = new Date();
  const fr = addHours(new Date(from), 1);

  if (currentDate.getTime() - fr.getTime() < 24 * 60 * 60 * 1000) {
    return formatDistanceToNowStrict(fr, { addSuffix: true });
  }

  return currentDate.getFullYear() === fr.getFullYear()
    ? format(fr, 'MMM d')
    : format(fr, 'MMM d, yyyy');
}
