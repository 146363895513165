import React from 'react';
import { Skeleton } from './atom/skeleton';

const MetadataPreviewLoader = () => {
  return (
    <div className="bg-pink-brand-muted w-full py-2 px-3">
      <div className="flex gap-4 w-full justify-between items-start">
        <div className="flex flex-col md:flex-row items-center justify-center gap-1">
          <Skeleton className="h-20 w-[90px]" />

          <div className="space-y-2">
            <Skeleton className="rounded-sm h-3.5 w-5 md:w-[60px]" />
            <div className="space-y-1">
              <Skeleton className="rounded-sm h-3 w-[150px] md:w-[300px]" />
              <Skeleton className="rounded-sm h-3 w-[150px] md:w-[300px]" />
            </div>
            <Skeleton className="rounded-sm h-3.5 w-2.5 md:w-[50px]" />
          </div>
        </div>
        <Skeleton className="rounded-full h-4 w-full" />
      </div>
    </div>
  );
};

export default MetadataPreviewLoader;
