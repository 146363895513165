import { toggleLike } from '@/services/posts.service';
import { PostType, ToggleInputType } from '@/types';
import { InvalidateQueryFilters, useMutation, useQueryClient } from '@tanstack/react-query';
import { useSession } from 'next-auth/react';
import { useToast } from '@/hooks/use-toast';
import { SortOrderEnum, SortPostByEnum } from '@/utils/enums';

const useToggleLikeMutation = (dataKey: string) => {
  const { toast } = useToast();
  const queryClient = useQueryClient();
  const allQueryKeys = queryClient
    .getQueryCache()
    .getAll()
    .map((query) => query.queryKey);

  const foundKey = allQueryKeys.find(([key]) => key === dataKey) ?? [];

  return useMutation({
    mutationFn: ({ contentId, parentType, like }: ToggleInputType) =>
      toggleLike({ contentId, parentType, like }),

    onMutate: async ({ contentId, like }) => {
      await queryClient.cancelQueries({ queryKey: foundKey });
      const previousPosts = queryClient.getQueryData(foundKey);
      queryClient.setQueryData<any>(foundKey, (old: any) => {
        if (!old) return old;
        const newOldPages = old.pages?.map((page: any) => ({
          ...page,
          data: page?.data?.map((post: PostType) =>
            post.id === contentId
              ? { ...post, likeCount: post.likeCount + (!like ? -1 : 1), isLiked: like }
              : post
          ),
        }));
        return { ...old, pages: newOldPages };
      });

      // Return a context object with the snapshotted value
      return { previousPosts };
    },

    onSuccess: (res, variables, context) => {
      queryClient.invalidateQueries(foundKey as InvalidateQueryFilters);
    },

    onError: (res, variables, context) => {
      // Rollback the optimistic update if mutation fails
      if (context?.previousPosts) {
        queryClient.setQueryData(foundKey, context.previousPosts);
      }
      toast({
        variant: 'destructive',
        description: res.message,
      });
    },
  });
};

export default useToggleLikeMutation;
