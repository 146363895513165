import { useDebounce } from '@/hooks/use-debounce';
import extractUrl from '@/utils/extract-url';
import React from 'react';

export default function useLoadPreview(value: string) {
  const debouncedValue = useDebounce(value);
  const [metadata, setMetadata] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const fetchMetadata = async (url: string) => {
    const controller = new AbortController();
    const timeout = setTimeout(() => {
      controller.abort();
    }, 10000); // 10 seconds timeout
    try {
      setLoading(true);
      const response = await fetch(`/api/preview?url=${encodeURIComponent(url)}`, {
        signal: controller.signal,
      });
      clearTimeout(timeout);
      const data = await response.json();

      setMetadata(data);
    } catch (error) {
      setMetadata(null);
    } finally {
      setLoading(false);
      clearTimeout(timeout);
    }
  };

  React.useEffect(() => {
    const url = extractUrl(debouncedValue);

    if (url) {
      fetchMetadata(url);
    } else {
      setMetadata(null);
    }
  }, [debouncedValue]);

  const handleClose = () => {
    setMetadata(null);
  };

  return {
    metadata,
    loading,
    handleClose,
  };
}
