export enum ContentTypeEnum {
  Post = 1,
  Comment = 2,
  Event = 3,
  Community = 4,
  User = 5,
  Issue = 6,
}

export enum GiveawayEnum {
  Post = 1,
  Comment = 2,
  Gift = 4,
}

export enum GenderEnum {
  'Unknown' = 0,
  'Male' = 1,
  'Female' = 2,
}

export enum EventTypeEnum {
  'WEEK' = 1,
  'UPCOMING' = 2,
  'PAST' = 3,
  'LIVE' = 4,
}

export enum CommunityTypeEnum {
  private = 2,
  public = 1,
}

export enum CreateShopItemStepEnum {
  Info = 'info',
  Lifetime = 'lifetime',
  Summary = 'summary',
}

export enum RoleEnum {
  Member = 0,
}

export enum SortOrderEnum {
  Ascending = 1,
  Descending = 2,
}

export enum SortPostByEnum {
  Default = 0,
  Comments = 1,
  Likes = 2,
  Views = 3,
}

export enum EventShopTypeEnum {
  Physical = 1,
  Virtual = 2,
}

export enum TicketType {
  Paid = 1,
  Free = 2,
}

export enum ChannelEnum {
  Mobile = '1',
  Web = '2',
}

export enum PostCallEnum {
  Explore = 'GetAllPosts',
  Feeds = 'GetUserFeedPosts',
  BySlug = 'GetPostBySlug',
  ByCommunity = 'GetPostsByCommunity',
  CommentBySlug = 'GetCommentsByContentSlug',
  CommentById = 'GetCommentsByContentId',
}
