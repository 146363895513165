import { createPostComment } from '@/services/posts.service';
import { CreateCommentPayloadType } from '@/types';
import { useMutation } from '@tanstack/react-query';

const useCreatePostCommentMutation = () => {
  return useMutation({
    mutationKey: ['create-post-comment'],
    mutationFn: (payload: CreateCommentPayloadType) => createPostComment(payload),
  });
};

export default useCreatePostCommentMutation;
