"use client";
import React from "react";
import { PlayIcon } from "../icons";

type Props = {
  src: string;
  height?: number;
};

const VideoView: React.FC<Props> = ({ height = 352, src }) => {
  const [showControls, setShowControls] = React.useState(false);
  return (
    <>
      <video
        controls={showControls}
        poster="https://cruip-tutorials.vercel.app/modal-video/modal-video-thumb.jpg"
        width="100%"
        onClick={() => setShowControls(!showControls)}
        className="h-full w-full object-cover"
      >
        <source src={src} />
      </video>

      {!showControls && (
        <button
          onClick={() => setShowControls(!showControls)}
          className="absolute top-[40%] left-[40%]"
        >
          <PlayIcon />
        </button>
      )}
    </>
  );
};

export default VideoView;
