'use client';

import { APP_DOMAIN } from '@/configs/global-variables';
import { cn } from '@/lib/utils';
import { X } from 'lucide-react';
import Image from 'next/image';
import React from 'react';

type Props = {
  data: {
    image: string;
    title: string;
    description: string;
    author: string;
  };
  handleClose?: () => void;
  className?: string;
};

const MetadataPreview = ({ data, handleClose, className }: Props) => {
  return (
    <div className={cn('bg-pink-brand-muted w-full py-2 px-3', className)}>
      <div className="flex justify-between gap-4 w-full items-start">
        <div className="flex flex-col md:flex-row justify-center items-center gap-1">
          {data.image ? (
            <Image
              height={80}
              width={80}
              className="w-full h-full"
              src={data.image || ''}
              alt={data.title}
              crossOrigin="anonymous"
              priority
            />
          ) : (
            <div className="h-24 w-full md:h-20 md:w-24 flex items-center justify-center bg-purple-brand3">
              <Image
                height={40}
                width={40}
                className="w-full h-full"
                src="/favicon/purple-logo.svg"
                alt={data.title}
                priority
              />
            </div>
          )}

          <div className="text-brand5 text-xs">
            <p className="text-gray-brand12 text-sm font-medium">{data.title}</p>
            <p className="text-nowrap overflow-ellipsis">{data.description}</p>
            <p>{APP_DOMAIN}</p>
          </div>
        </div>
        <button onClick={handleClose} type="button">
          <X size={16} className="text-gray-brand9" />
        </button>
      </div>
    </div>
  );
};

export default MetadataPreview;
