export default function extractUrl(text: string): string | null {
  // Define the regex pattern for URLs
  const urlPattern = /(https?:\/\/[^\s/$.?#].[^\s]*|http:localhost[^\s]*)/gi;
  // /(https?:\/\/(?:localhost|(?:\d{1,3}\.){3}\d{1,3}|[a-zA-Z0-9.-]+)(?::\d+)?(?:\/[^\s]*)?)/gi;

  if (text) {
    // Search for the first URL in the text
    const match = text.match(urlPattern);

    if (match) return match[0]; // Return the first matched URL
    return null; // No URL found
  }

  return null;
}
